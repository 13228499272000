const prod = {

	url: {
		// API_URL: '/api/',
		API_URL: 'https://api.repulsivecoder.pro/',
	}
};

const dev = {
	url: {
		API_URL: 'http://localhost/repulsivecoder.pro/api/'
	}
};

const dev_ssh = {
	url: {
		API_URL: 'http://172.16.1.13/repulsivecoder.pro/api/'
	}
};

export const config = process.env.NODE_ENV === 'development' ? (process.env.REACT_APP_RUN_REMOTE ? dev_ssh : dev) : prod;

// console.log(process.env);