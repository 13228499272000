import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GithubIcon from "@mui/icons-material/GitHub";
// import TwitterIcon from "@mui/icons-material/Twitter";
import XIcon from '@mui/icons-material/X';
import FacebookIcon from "@mui/icons-material/Facebook";
//import TikTokIcon from "@mui/icons-material/LibraryMusic";

import "../styles/Footer.css";


const TikTokIcon = ({ color = "#FFFFFF" }) => {
  return (
    <div style={{height: '60px', width: '80px', display: 'inline-block', maxHeight: '60px'}}><svg
	  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
      fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={{width: '100%', height: '100%'}} >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg></div>
  );
};


function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
		  <a href="https://www.linkedin.com/in/abdullah-ibne-alam/" title="Abdullah Ibne Alam - LinkedIn Profile" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
		  <a href="https://github.com/RepulsiveCoder/" title="Abdullah Ibne Alam - Github" target="_blank" rel="noreferrer"><GithubIcon /></a>
		  <a href="https://www.facebook.com/RepulsiveCoder"title="Abdullah Ibne Alam - Facebook Profile"  target="_blank" rel="noreferrer"><FacebookIcon /></a>
		  <a href="https://x.com/RepulsiveCoder" title="Abdullah Ibne Alam - X" target="_blank" rel="noreferrer"><XIcon /></a>
		  <a href="https://www.tiktok.com/@repulsivecoder" title="Abdullah Ibne Alam - Tiktok" target="_blank" rel="noreferrer"><TikTokIcon /></a>
      </div>
      <p> &copy; 2023 - {new Date().getFullYear()} repulsivecoder.pro</p>
    </div>
  );
}

export default Footer;
