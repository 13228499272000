import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import PreviewIcon from '@mui/icons-material/Preview';
import LinkIcon from '@mui/icons-material/Link';
import "../styles/ProjectDisplay.css";
import { config } from '../Constants'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Moment from 'moment';

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '400px'
}


function ProjectDisplay()
{
	const navigate = useNavigate();
	const { src } = useParams();

	const [project, setProject] = useState(null);

	const baseURL = config.url.API_URL;
	const init = async () => {
		const url = baseURL + 'project/' + src;

		fetch(url, {method: 'GET', headers: { 'Content-Type' : 'application/json', mode:'cors' }  }).then((res) => res.json()).then((data) => { if (data.success) setProject(data.data); } );
	};

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		init(); }, []);

  return (!!project && 
	(<div className="project">
		<h1> {project.title}</h1>
		{project?.images?.length > 1 &&
			(<div className="slide-container">
				<Slide>
				{project.images.map((slideImage, index)=> (
				<div key={index}>
				<div style={{ ...divStyle, 'backgroundImage': `url(/media/project/${slideImage})` }}>
				</div>
				</div>
				))} 
				</Slide>
			</div>)}

		{project?.images?.length === 1 && (<div style={{ width: '700px', ...divStyle, 'backgroundImage': `url(/media/project/${project.images[0]})` }} />)}
		<fieldset>
			<legend>Description</legend>
			<div className="description" dangerouslySetInnerHTML={{ __html: project.description }} />

			<div className="technology">
				<b>Technology:</b> {project.technology}
			</div>

			<div className="description">
				<b>Published:</b> {project.displayonlyyear === '1' ? Moment(project.published).format('Y') : project.hidedate === '1' ? Moment(project.published).format('MMMM Y') : Moment(project.published).format('MMMM D, Y')}
			</div>
			
			{!!project.dates && project.dates.length > 0 && (
				<div className="description">
				<b>Versions:</b>
				<div className="versions">
				{project.dates.map((dt, dk) => {
				return (<div key={dk}>{dt.version}, <b>Published:</b> {project.displayonlyyear === '1' ? Moment(dt.date).format('Y') : project.hidedate === '1' ? Moment(dt.date).format('MMMM Y') : Moment(dt.date).format('MMMM D, Y')}</div>);
				})}
				</div>
				</div>
			)}

			{(!!project.demo || !!project.url || !!project.github) && (
				<div className="external-links">
					{!!project.demo && (
						<a href={`${project.demo}`} target="_blank" className="button" rel="noreferrer"><PreviewIcon /> Demo</a>
					) }

					{!!project.url && (
						<a href={`${project.url}`} target="_blank" className="button" rel="noreferrer"><LinkIcon /> Website</a>
					) }

					{!!project.github && (
						<a href={`${project.github}`} target="_blank" className="button" rel="noreferrer"><GitHubIcon /> Github</a>
					) }
				</div>
			) }
			
		</fieldset>
		<div className="buttonContainer">
			<button className="button" onClick={() => { navigate("/projects" ); }}>Back </button>
		</div>
		</div>)
	);
}

export default ProjectDisplay;
