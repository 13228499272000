import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";
import ReorderIcon from "@mui/icons-material/Reorder";
import RepulsiveCoder from "../assets/RepulsiveCoder.png";

function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);

  const location = useLocation();
  
  useEffect(() => {
    setExpandNavbar(false);
  }, [location]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [navbarClass, setnavbarClass] = useState('navbar');

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 50) {
      setnavbarClass('navbar shrink');
    } else {
      setnavbarClass('navbar');
    }
  }, [scrollPosition]);


  return (
    <div className={navbarClass} id={expandNavbar ? "open" : "close"} >
      <div className="toggleButton">
        <button
          onClick={() => {
            setExpandNavbar((prev) => !prev);
          }} >
          <ReorderIcon />
        </button>
      </div>
      <div className="links">
        <img src={RepulsiveCoder} id="thehooman" alt="thehooman" />
        <Link to="/"> Home </Link>
        <Link to="/projects"> Projects </Link>
        <Link to="/experience"> Experience </Link>
      </div>
    </div>
  );
}

export default Navbar;
