import React, { useEffect, useState } from "react";
import ProjectItem from "../components/ProjectItem";
import { config } from '../Constants'

import "../styles/Projects.css";
import Moment from 'moment';


function Projects()
{
	const [pitems, setProject] = useState([]);

	const baseURL = config.url.API_URL;

	const init = async () => {
		const url = baseURL + 'projects';

		fetch(url, {method: 'GET', headers: { 'Content-Type' : 'application/json', mode:'cors' }  }).then((res) => res.json()).then((data) => { if (data.success) setProject(data.data); } );
	};

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		init(); }, []);


	return (
	<div className="projects">
	  <h1> Projects</h1>
	  <div className="projectList">
		{pitems.map((project, idx) => {

		var pdate = Moment(project.published).format('MMMM D, Y');

		if (project.displayonlyyear === '1')
		{
			pdate = Moment(project.published).format('Y');
		}
		else if (project.hidedate === '1')
		{
			pdate = Moment(project.published).format('MMMM Y');
		}

		return (
			<ProjectItem key={idx} id={project.id} name={project.title} uri={project.uri} images={project.images} date={pdate} />
		  );
		})}
	  </div>
	</div>
	);
}

export default Projects;
